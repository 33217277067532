<div class="accordion" id="accordionMain">
  <div class="card" *ngFor="let item of data; let i = index">
    <div class="card-header" id="{{item.headerId}}">
      <h2 class="mb-0">
        <button class="btn btn-block text-left" type="button" (click)="toggleArrow(item)"
            [attr.data-target]="'#' + item.bodyId" [attr.aria-controls]="item.bodyId" data-toggle="collapse" aria-expanded="true">
          <div class="d-flex justify-content-between">
            <span class="btn-link">{{item.title}}</span>
            <fa-icon [icon]="item.arrow"></fa-icon>
          </div>
        </button>
      </h2>
    </div>
    <div id="{{item.bodyId}}" class="collapse" [ngClass]="{'show': i == 0}" [attr.aria-labelledby]="item.headerId" data-parent="#accordionMain">
      <div class="card-body" style="white-space: pre-line">
        {{item.text}}
      </div>
    </div>
  </div>
</div>
