import { HostListener, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '../app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from '../components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { DocumentsComponent } from '../components/documents/documents.component';
import { WhatsNewComponent } from '../components/whats-new/whats-new.component';
import { AccordionComponent } from '../components/accordion/accordion.component';
import { LogService } from 'src/app/services/log.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from '../components/toast/toast.component';
import { FileDndDirective } from '../directives/file-dnd.directive';
import { DropdownItemActionsComponent } from '../components/dropdown-actions/dropdown-actions.component';
import { ModalUploadFileComponent } from '../components/modal-upload-file/modal-upload-file.component';
import { ModalCreateFolderComponent } from '../components/modal-create-folder/modal-create-folder.component';
import { ModalConfirmComponent } from '../components/modal-confirm/modal-confirm.component';
import { AuthenticationInterceptor } from '../interceptors/authentication.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    DocumentsComponent,
    WhatsNewComponent,
    ToastComponent,
    FileDndDirective,
    DropdownItemActionsComponent,
    ModalUploadFileComponent,
    ModalCreateFolderComponent,
    ModalConfirmComponent,
    AccordionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgbModule,
  ],
  providers: [
    LogService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private cookieService: CookieService) {
    // window.addEventListener('beforeunload', function (e) {
    //   // Cancel the event
    //   e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    //   // Chrome requires returnValue to be set
    //   cookieService.set('test', 'beforeunload test', undefined, '/', environment.cookieDomain, true, 'Lax');
    //   //e.returnValue = '';
    // });
    // window.addEventListener('unload', function (e) {
    //   cookieService.deleteAll('/', environment.cookieDomain, true, 'Lax');
    // });
  }

  // @HostListener('window:beforeunload', ['$event'])
  // unloadHandler(event: Event) {
  //   event.preventDefault();

  //   event.returnValue = false; //'You have unsaved changes, leave anyway?';

  //   //this.cookieService.set('test', 'beforeunload test', undefined, '/', environment.cookieDomain, true, 'Lax');

  //   // if(this.cookieService.check('refCount')) {
  //   //   let refCount:number = Number(this.cookieService.get('refCount'));
  //   //   refCount--;
  //   //   if(refCount <= 0) {
  //   //     //delete all
  //   //   } else {
  //   //     this.cookieService.set('refCount', refCount.toString(), undefined, '/', environment.cookieDomain, true, 'Lax');
  //   //   }
  //   // }

  // }
}
