import { Component } from '@angular/core';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../services/auth.service';
import { ControlRoutes } from "../../models/constants";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {

  routes = ControlRoutes;
  faUser = faUser;

  constructor(private authService: AuthService) {}

  UserName() {
    return this.authService.userName;
  }

  onLogout() {
    console.log('navbar logout');
    this.authService.logout();
  }
}
