import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { AppToastService } from '../../services/app-toast.service';

@Component({
  selector: 'app-modal-upload-file',
  templateUrl: './modal-upload-file.component.html',
  styleUrls: ['./modal-upload-file.component.scss'],
})
export class ModalUploadFileComponent {
  control: FormControl;
  faCloudArrowUp = faUpload;

  @Input() loading: boolean = false;
  @Input() progress: number = 0;
  @Input() allowedExtensions: any[] = [];
  @Output() uploadEvent = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private toastService: AppToastService
  ) {
    this.control = this.fb.control(
      { value: '', disabled: false },
      { validators: Validators.required }
    );
  }

  onFileDropped(files: any): void {
    const file: File = files[0];

    if (
      this.allowedExtensions.some((item: any) =>
        file.name.toLowerCase().endsWith(item.extension)
      )
    ) {
      this.onFileHandler(file);
    } else {
      this.toastService.show(
        `Oops! An error has occurred`,
        `The file extension is not allowed`
      );
    }
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.onFileHandler(file);
  }

  onFileHandler(file: any) {
    this.control.setValue(file);
  }

  acceptedExtensions(): string {
    let extensions: string = '';

    this.allowedExtensions.forEach((item: any) => {
      extensions += `${item.extension},`;
    });

    return extensions;
  }

  uploadFile(): void {
    this.uploadEvent.emit(this.control);
  }
}
