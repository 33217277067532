import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faList,
  faTh,
  faSync,
  faInfoCircle,
  faEllipsisVertical,
  faCloudDownloadAlt,
  faTrashAlt,
  faSpinner,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFilePdf,
  faFileImage,
  faFileWord,
  faFileExcel,
  faFolder,
  faFile,
  faFileAlt,
  faFileArchive,
} from '@fortawesome/free-regular-svg-icons';

import { DocumentsService } from '../../services/documents.service';
import { Document } from '../../models/document';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { DocsPath } from '../../models/docsPath';
import { LogService } from 'src/app/services/log.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateFolderComponent } from '../modal-create-folder/modal-create-folder.component';
import { AppToastService } from '../../services/app-toast.service';
import { ModalUploadFileComponent } from '../modal-upload-file/modal-upload-file.component';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  faList = faList;
  faTh = faTh;
  faSync = faSync;
  faFilePdf = faFilePdf;
  faFileImage = faFileImage;
  faFileWord = faFileWord;
  faFileExcel = faFileExcel;
  faFolder = faFolder;
  faFile = faFile;
  faFileAlt = faFileAlt;
  faFileArchive = faFileArchive;
  faCloudDownloadAlt = faCloudDownloadAlt;
  faTrashAlt = faTrashAlt;
  faSpinner = faSpinner;
  faChevronLeft = faChevronLeft;
  faInfoCircle = faInfoCircle;
  faEllipsisVertical = faEllipsisVertical;

  viewMode: string | null = null;
  loadingData: boolean = false;
  errorMessage: string = '';
  noDocsMessage: string = '';

  docs: Document[] = [];
  docsPath: DocsPath[] = [];
  alowedExtensions: string = '';
  apiDocsPath: string = '';

  pathActions: any[] = [
    { actionId: 'createFolder', label: 'Create folder' },
    { actionId: 'uploadFile', label: 'Upload file' },
  ];

  folderActions: any[] = [{ actionId: 'deleteFolder', label: 'Delete folder' }];

  fileActions: any[] = [{ actionId: 'deleteFile', label: 'Delete file' }];

  constructor(
    private authService: AuthService,
    private docsService: DocumentsService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private location: Location,
    private logger: LogService,
    private modalService: NgbModal,
    private toastService: AppToastService
  ) {}

  ngOnInit(): void {
    //Note: ngOnInit in this case will run every time due to routing setting: routeReuseStrategy.shouldReuseRoute = false

    let apiPath = this.actRoute.snapshot.url;
    this.apiDocsPath = this.buildDocApiUrl(apiPath);

    this.viewMode = sessionStorage.getItem('viewMode');
    if (this.viewMode === null) this.viewMode = '0';
    this.loadDocs();
    this.buildBreadcrumbArray();
    this.getExtensionAllowed();
  }

  private buildBreadcrumbArray() {
    let p = this.apiDocsPath.split('/');
    p.shift();

    let fullPath: string = '';

    p.forEach((element) => {
      fullPath = fullPath + '/' + element;
      let o = new DocsPath();
      o.name = element;
      o.fullPath = fullPath;
      this.docsPath?.push(o);
    });

    this.docsPath[0].name = 'Documents';
  }

  loadDocs() {
    this.loadingData = true;
    this.errorMessage = '';
    this.noDocsMessage = '';

    this.docsService.getAllDocuments(this.apiDocsPath).subscribe({
      next: (data: any) => this.processDocsReturn(data),
      error: (err: any) => this.handleError(err),
    });
  }

  private getExtensionAllowed() {
    this.docsService.getExtensionAllowed().subscribe({
      next: (data: any) => {
        this.alowedExtensions = data;
      },
    });
  }

  private processDocsReturn(data: Document[]) {
    this.docs = data;
    this.loadingData = false;
    if (!data || data.length === 0) {
      this.noDocsMessage = 'There are no documents to view.';
    } else {
      this.noDocsMessage = '';
    }

    //this.logger.information("Data loaded", this.docs);
  }

  private handleError(err: any) {
    //TODO log to server
    this.loadingData = false;

    if (err.status === 401) {
      this.authService.logout();
    } else {
      this.errorMessage =
        'Oops! An Error has occurred. Please give it another try. (Error: ' +
        err.status +
        '-' +
        err.statusText +
        ')'; //TODO change
    }
  }

  private toastError(err: any) {
    //TODO log to server
    this.loadingData = false;

    if (err.status === 401) {
      this.authService.logout();
    } else {
      this.toastService.show(`Oops! An error has occurred`, err.error);
    }
  }

  getIconForFileType(fileType: string | ''): IconProp {
    switch (fileType.toUpperCase()) {
      case '.PDF':
        return faFilePdf;
      case '.DOCX':
      case '.DOC':
        return faFileWord;
      case '.XLSX':
      case '.XLS':
        return faFileExcel;
      case '.JPG':
      case '.JPEG':
      case '.TIFF':
        return faFileImage;
      case '.TXT':
      case '.RTF':
        return faFileAlt;
      case '.ZIP':
        return faFileArchive;
      default:
        return faFile;
    }
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  buildDocApiUrl(url: UrlSegment[]) {
    let apiUrl = '';

    url.forEach((s) => {
      apiUrl = apiUrl + '/' + s.path;
    });

    return apiUrl;
  }

  onViewModeListClick() {
    this.viewMode = '0';
    sessionStorage.setItem('viewMode', '0');
  }

  onViewModeIconClick() {
    this.viewMode = '1';
    sessionStorage.setItem('viewMode', '1');
  }

  onRefreshClick() {
    this.loadDocs();

    this.logger.information('Refresh clicked');
  }

  onBackClick() {
    this.location.back();
  }

  onDocLinkClick(fileName: string) {
    this.loadingData = true;
    this.docsService.getFileUrl(`${this.apiDocsPath}/${fileName}`).subscribe({
      next: (data: any) => {
        this.loadingData = false;
        window.open(data.url, '_blank');
      },
      error: (err: any) => this.toastError(err),
    });
  }

  actionListener(actionId: string, fileName?: string) {
    switch (actionId) {
      case 'createFolder':
        const createFolderModal = this.modalService.open(
          ModalCreateFolderComponent,
          {
            backdrop: 'static',
            keyboard: false,
          }
        );

        createFolderModal.componentInstance.createEvent.subscribe(
          ($event: any) => {
            this.docsService
              .createFolder($event.value.trim(), this.apiDocsPath)
              .subscribe({
                next: () => {
                  createFolderModal.close();
                  this.loadDocs();
                },
                error: (err: any) => this.toastError(err),
              });
          }
        );
        break;
      case 'deleteFile':
        const deleteFileModal = this.modalService.open(ModalConfirmComponent);

        deleteFileModal.componentInstance.title = 'Delete file';
        deleteFileModal.componentInstance.body = 'Are you sure...?';

        deleteFileModal.result.then(
          () => {
            this.docsService
              .deleteFile(`${this.apiDocsPath}/${fileName}`)
              .subscribe({
                next: (data: any) => this.loadDocs(),
                error: (err: any) => this.toastError(err),
              });
          },
          () => {}
        );
        break;
      case 'deleteFolder':
        const deleteFolderModal = this.modalService.open(ModalConfirmComponent);

        deleteFolderModal.componentInstance.title = 'Delete folder';
        deleteFolderModal.componentInstance.body = 'Are you sure...?';

        deleteFolderModal.result.then(
          () => {
            this.docsService
              .deleteFolder(`${this.apiDocsPath}/${fileName}`)
              .subscribe({
                next: (data: any) => this.loadDocs(),
                error: (err: any) => this.toastError(err),
              });
          },
          () => {}
        );
        break;
      case 'uploadFile':
        const uploadModal = this.modalService.open(ModalUploadFileComponent, {
          backdrop: 'static',
          keyboard: false,
        });

        uploadModal.componentInstance.allowedExtensions = this.alowedExtensions;

        uploadModal.componentInstance.uploadEvent.subscribe(($event: any) => {
          if (!!$event) {
            uploadModal.componentInstance.loading = true;

            const uploadData = new FormData();
            uploadData.append('file', $event.value);

            this.docsService
              .uploadFile(uploadData, this.apiDocsPath)
              .subscribe({
                next: (data: any) => {
                  uploadModal.componentInstance.progress = data;
                },
                complete: () => {
                  uploadModal.close();
                  this.loadDocs();
                },
                error: (err: any) => {
                  uploadModal.componentInstance.loading = false;
                  this.toastError(err);
                },
              });
          }
        });
        break;
      default:
        this.loadDocs();
        break;
    }
  }
}
