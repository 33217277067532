<app-navbar></app-navbar>

<nav class="navbar navbar-light">
  <div class="container-fluid">
    <div>
      <ng-template [ngIf]="this.docsPath.length > 1">
        <button class="btn btn-light btn-sm" (click)="onBackClick()">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>
      </ng-template>

      <button class="btn btn-light btn-sm" (click)="onRefreshClick()">
        <fa-icon [icon]="faSync"></fa-icon>
      </button>
    </div>

    <div>
      <ng-template [ngIf]="this.viewMode === '1'">
        <button class="btn btn-light btn-sm" (click)="onViewModeListClick()">
          <fa-icon [icon]="faList"></fa-icon>
        </button>
        <button class="btn btn-light btn-sm border-dark">
          <fa-icon [icon]="faTh"></fa-icon>
        </button>
      </ng-template>
      <ng-template [ngIf]="this.viewMode === '0'">
        <button class="btn btn-light btn-sm border-dark">
          <fa-icon [icon]="faList"></fa-icon>
        </button>
        <button class="btn btn-light btn-sm" (click)="onViewModeIconClick()">
          <fa-icon [icon]="faTh"></fa-icon>
        </button>
      </ng-template>
    </div>
  </div>
</nav>

<div
  aria-label="breadcrumb"
  class="bg-white row align-items-center m-1 px-1 py-2 border-bottom"
>
  <div class="col-8 ps-0">
    <ol class="breadcrumb bg-white bottom mb-0 py-0">
      <li *ngFor="let p of docsPath" class="breadcrumb-item">
        <a routerLink="{{ p.fullPath }}">{{ p.name }}</a>
      </li>
    </ol>
  </div>
  <div class="col-4 text-end">
    <app-dropdown-actions
      [actions]="pathActions"
      (onActionClick)="actionListener($event)"
    ></app-dropdown-actions>
  </div>
</div>

<div class="container-fluid px-1">
  <ng-template [ngIf]="this.loadingData && !this.errorMessage">
    <div class="d-flex justify-content-center" style="padding: 50px">
      <fa-icon [icon]="faSpinner" [animation]="'spin-pulse'" size="3x"></fa-icon>
    </div>
  </ng-template>
  <ng-template [ngIf]="this.errorMessage">
    <div class="alert alert-info" style="margin: 10px; padding: 20px">
      <fa-icon [icon]="faInfoCircle" class="me-2"></fa-icon
      >{{ this.errorMessage }}
    </div>
  </ng-template>
  <ng-template
    [ngIf]="this.noDocsMessage && !this.loadingData && !this.errorMessage"
  >
    <div class="alert alert-info" style="margin: 10px; padding: 20px">
      <fa-icon [icon]="faInfoCircle" class="me-2"></fa-icon
      >{{ this.noDocsMessage }}
    </div>
  </ng-template>

  <ng-template
    [ngIf]="!this.loadingData && !this.errorMessage && !noDocsMessage"
  >
    <!-- List View -->
    <ng-template [ngIf]="this.viewMode === '0'">
      <div
        *ngFor="let doc of docs"
        class="row align-items-center m-1 p-1 border-bottom"
      >
        <ng-template [ngIf]="doc.isFolder">
          <div class="col-9 p-2">
            <span class="me-3"
              ><fa-icon [icon]="faFolder" size="2x"></fa-icon
            ></span>
            <a routerLink="{{ apiDocsPath + '/' + doc.name + '/' }}">
              <span class="font-weight-bold">{{ doc.name }}</span>
            </a>
          </div>
          <div class="col-3 text-end">
            <app-dropdown-actions
              [isIcon]="true"
              [actions]="folderActions"
              (onActionClick)="actionListener($event, doc.name)"
            ></app-dropdown-actions>
          </div>
        </ng-template>

        <ng-template [ngIf]="!doc.isFolder">
          <div class="col-6">
            <span class="me-3"
              ><fa-icon
                [icon]="getIconForFileType(doc.extension!)"
                size="2x"
              ></fa-icon
            ></span>

            <button
              type="button"
              class="btn btn-link p-0 tex"
              (click)="onDocLinkClick(doc.name)"
            >
              {{ doc.name }}
            </button>
          </div>

          <div class="col-3">
            <div class="me-0">
              {{ formatBytes(doc.blobItem!.properties.contentLength) }}
            </div>
            <div class="me-0" style="font-size: small">
              Created: {{ doc.blobItem!.properties.createdOn | date : "short" }}
            </div>
          </div>
          <!-- <ng-template [ngIf]="!doc.isFolder">
            <div class="col">
              <a href="#" routerLink="{{ doc.name }}">
                <span class="me-3"><fa-icon [icon]="faCloudDownloadAlt"></fa-icon></span>
              </a>
              <span class="ms-auto"><fa-icon [icon]="faTrashAlt"></fa-icon></span>
            </div>
          </ng-template> -->
          <div class="col-3 text-end">
            <app-dropdown-actions
              [isIcon]="true"
              [actions]="fileActions"
              (onActionClick)="actionListener($event, doc.name)"
            ></app-dropdown-actions>
          </div>
        </ng-template>
      </div>
      <!-- End ngFor -->
    </ng-template>

    <!-- Tile View -->
    <ng-template [ngIf]="!this.loadingData && this.viewMode === '1'">
      <div class="row mx-0">
        <div
          *ngFor="let doc of docs"
          class="col-6 col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-2 py-2 px-1"
        >
          <div class="card p-2">
            <!-- <img class="card-img-top" src="..." alt="Card image cap"> -->
            <!-- <fa-icon [icon]="faFile" size="lg"></fa-icon> -->
            <div class="card-body d-flex flex-column p-2">
              <ng-template [ngIf]="doc.isFolder">
                <div class="row">
                  <div class="col">
                    <span><fa-icon [icon]="faFolder" size="2x"></fa-icon></span>
                  </div>
                  <div class="col text-end">
                    <app-dropdown-actions
                      [isIcon]="true"
                      [actions]="folderActions"
                      (onActionClick)="actionListener($event, doc.name)"
                    ></app-dropdown-actions>
                  </div>
                </div>
                <a routerLink="{{ apiDocsPath + '/' + doc.name + '/' }}">
                  <div class="mt-1 font-weight-bold">{{ doc.name }}</div>
                </a>
              </ng-template>

              <ng-template [ngIf]="!doc.isFolder">
                <div class="row">
                  <div class="col">
                    <span
                      ><fa-icon
                        [icon]="getIconForFileType(doc.extension!)"
                        size="2x"
                      ></fa-icon
                    ></span>
                  </div>
                  <div class="col text-end">
                    <app-dropdown-actions
                      [isIcon]="true"
                      [actions]="fileActions"
                      (onActionClick)="actionListener($event, doc.name)"
                    ></app-dropdown-actions>
                  </div>
                </div>

                <div class="row mt-1 mb-2 mx-0">
                  <button
                    type="button"
                    class="btn btn-link p-0 text-start text-truncate"
                    (click)="onDocLinkClick(doc.name)"
                  >
                    {{ doc.name }}
                  </button>
                </div>

                <p class="card-text mb-1">
                  {{ formatBytes(doc.blobItem!.properties.contentLength) }}
                </p>
                <p class="card-text" style="font-size: small">
                  {{ doc.blobItem!.properties.createdOn | date : "short" }}
                </p>
              </ng-template>

              <!-- <ng-template [ngIf]="!doc.isFolder">
              <div class="mt-auto">
                <span class="me-3"><fa-icon [icon]="faCloudDownloadAlt"></fa-icon></span>
                <span class="me-auto"><fa-icon [icon]="faTrashAlt"></fa-icon></span>
              </div>
            </ng-template> -->
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
