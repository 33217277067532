import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !request.url.endsWith('auth/token') &&
      !request.url.endsWith('auth/ping') &&
      !request.url.endsWith('auth/logout')
    ) {
      this.authService.setCookiePing();
      this.authService.authPing();
    }

    const authToken = `Bearer ${this.cookieService.get('token')}`;

    request = request.clone({
      setHeaders: {
        Authorization: authToken,
      },
    });

    return next.handle(request);
  }
}
