import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../interceptors/auth.guard';
import { DocumentsComponent } from '../components/documents/documents.component';
import { LoginComponent } from '../components/login/login.component';
import { ControlRoutes } from '../models/constants';
import { WhatsNewComponent } from "../components/whats-new/whats-new.component";

const routes: Routes = [
  { path: ControlRoutes.Login, component: LoginComponent },
  { path: ControlRoutes.Documents, component: DocumentsComponent, canActivate: [AuthGuard] },
  { path: ControlRoutes.WhatsNew, component: WhatsNewComponent, canActivate: [AuthGuard] },
  { path: '**', component: DocumentsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    //force same route to re init every time
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
}
