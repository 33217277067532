import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export class AccordionItem {
  title: string = "";
  text: string = "";
  arrow: IconDefinition = faChevronDown;
  headerId: string = "";
  bodyId: string = "";

  public constructor(init?: Partial<AccordionItem>) {
    Object.assign(this, init);
  }
}
